export default {
  DELETE_CLIENT_MODAL: "DELETE_CLIENT",
  DELETE_PARTNER_MODAL: "DELETE_PARTNER",
  ADD_INTERNAL_PARTNER_MODAL: "ADD_INTERNAL_PARTNER",
  ADD_COUNTRY_MODAL: "ADD_COUNTRY",
  EDIT_COUNTRY_MODAL: "EDIT_COUNTRY",
  TOGGLE_OFFER_MODAL: "TOGGLE_OFFER",
  DELETE_OFFER_MODAL: "DELETE_OFFER",
  TOGGLE_AGENCY_MODAL: "TOGGLE_AGENCY",
  USER_RESET_PASSWORD_MODAL: "USER_RESET_PASSWORD",
  USER_BLOCK_MODAL: "USER_BLOCK",
  USER_DELETE_MODAL: "USER_DELETE",
  DELETE_RECEIPT_MODAL: "DELETE_RECEIPT",
  ADD_CODEBOOK_MODAL: "ADD_CODEBOOK",
  EDIT_CODEBOOK_MODAL: "EDIT_CODEBOOK",
  DELETE_CODEBOOK_MODAL: "DELETE_CODEBOOK",
  CLOSE_CONFIRM_DIALOG: "CLOSE_CONFIRM_DIALOG",
  DELETE_LOGO_MODAL: "DELETE_LOGO",
  DELETE_SIGNATURE_MODAL: "DELETE_SIGNATURE",
  DELETE_IMAGE_MODAL: "DELETE_IMAGE",
  DELETE_STATEMENT_MODAL: "DELETE_STATEMENT",
};
