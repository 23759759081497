import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  number: Yup.string().required(
    i18next.t("field.statementNumberRequireduired")
  ),
  date: Yup.date()
    .required(i18next.t("field.statementDateRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
  accountNumber: Yup.string().required(
    i18next.t("field.statementAccountNumberRequired")
  ),
  items: Yup.array().of(
    Yup.object().shape({
      creditDebit: Yup.string().required(
        i18next.t("field.creditDebitRequired")
      ),
      clientId: Yup.string().required(
        i18next.t("field.clientIdRequired")
      ),
      invoiceNumber: Yup.string().required(
        i18next.t("field.invoiceNumberRequired")
      ),
      totalAmount: Yup.number().required(
        i18next.t("field.totalAmountRequired")
      ),
      exchangeRate: Yup.number().required(
        i18next.t("field.exchangeRateRequired")
      ),
    })
  ),
});
