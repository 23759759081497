import React, { useState } from "react";
import PropTypes from "prop-types";
import { StyledTab, StyledTabs, TabPanelContainer } from "./Tabs.styled";
import { Box } from "@mui/material";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <TabPanelContainer
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </TabPanelContainer>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const TabsComponent = ({ tabs, tabId }) => {
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(tabId || 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Box>
        <StyledTabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="ant example"
          TabIndicatorProps={{ sx: { display: "none" } }}
          sx={{
            "& .MuiTabs-flexContainer": {
              flexWrap: "wrap",
            },
          }}
        >
          {tabs.map((tab, index) => (
            <StyledTab label={tab.label} key={index} />
          ))}
        </StyledTabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel value={value} index={index} key={index}>
          {tab.component}
        </TabPanel>
      ))}
    </Box>
  );
};

TabsComponent.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  tabId: PropTypes.number,
};

export default TabsComponent;
