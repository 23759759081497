import { PAGES } from "./pages";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptIcon from '@mui/icons-material/Receipt';
import CategoryIcon from '@mui/icons-material/Category';
import PublicIcon from '@mui/icons-material/Public';
import MapIcon from '@mui/icons-material/Map';
import HotelIcon from '@mui/icons-material/Hotel';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import VillaIcon from '@mui/icons-material/Villa';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import RestaurantIcon from '@mui/icons-material/Restaurant';


export const headerNavigationConstants = [
  PAGES.HOME,
  PAGES.DASHBOARD,
  PAGES.PROFILE,
  PAGES.SETTINGS,
];

export const homePageNavigationLinks = [
  {
    title: PAGES.CLIENTS.title,
    path: PAGES.CLIENTS.route,
    PTP: true,
    icon: PeopleAltIcon
  },
  {
    title: PAGES.OFFERS.title,
    path: PAGES.OFFERS.route,
    PTP: false,
    icon: LocalOfferIcon
  },
  {
    title: PAGES.SALES.title,
    path: PAGES.SALES.route,
    PTP: true,
    icon: ShoppingCartIcon
  },
  {
    title: PAGES.PAYMENT.title,
    path: PAGES.PAYMENT.route,
    PTP: false,
    icon: AttachMoneyIcon
  },
  {
    title: PAGES.STATEMENTS.title,
    path: PAGES.STATEMENTS.route,
    PTP: true,
    icon: DescriptionIcon
  },
  {
    title: PAGES.RECEIPTS.title,
    path: PAGES.RECEIPTS.route,
    PTP: true,
    icon: ReceiptIcon
  }
]


export const codebookPageNavigationLinks = [
          {
            title: PAGES.CATEGORY.title,
            path: PAGES.CATEGORY.route,
            icon: CategoryIcon,
          },
          {
            title: PAGES.COUNTRY.title,
            path: PAGES.COUNTRY.route,
            icon: PublicIcon,
          },
          {
            title: PAGES.LOCATION.title,
            path: PAGES.LOCATION.route,
            icon: MapIcon,
          },
          {
            title: PAGES.ACCOMMODATION_TYPE.title,
            path: PAGES.ACCOMMODATION_TYPE.route,
            icon: SingleBedIcon,
          },
          {
            title: PAGES.ACCOMMODATION.title,
            path: PAGES.ACCOMMODATION.route,
            icon: HotelIcon,
          },
          {
            title: PAGES.ACCOMMODATION_UNIT_TYPE.title,
            path: PAGES.ACCOMMODATION_UNIT_TYPE.route,
            icon: VillaIcon,
          },
          {
            title: PAGES.ACCOMMODATION_UNIT_PROPERTY.title,
            path: PAGES.ACCOMMODATION_UNIT_PROPERTY.route,
            icon: MapsHomeWorkIcon,
          },
          {
            title: PAGES.SERVICES.title,
            path: PAGES.SERVICES.route,
            icon: RestaurantIcon,
          },
          {
            title: PAGES.TRANSPORTATION_TYPE.title,
            path: PAGES.TRANSPORTATION_TYPE.route,
            icon: AirportShuttleIcon,
          },
          {
            title: PAGES.OFFER_TYPE.title,
            path: PAGES.OFFER_TYPE.route,
            icon: CardGiftcardIcon,
          },
]
