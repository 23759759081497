import React from "react";

const ProinvoicePage = () => {
  return (
    <>
      <p>Pro-invoice page</p>
    </>
  );
};

export default ProinvoicePage;
