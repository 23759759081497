import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { SUPERADMIN_ROLE } from "constants/roleConstants";
import { homePageNavigationLinks } from "constants/navigationConstants";
import { OTP_TYPE } from "constants/agencyType";
import useAuth from "hooks/useAuth";
import AdminPage from "pages/AdminPage/AdminPage";
import { Link } from "react-router-dom";
import { selectCurrentAgencyType } from "features/auth/authSlice";
import { HomePageCard } from "./HomePage.styled";
import { useTranslation } from "react-i18next";



const Card = ({ children, isOtp, path, Icon }) => {

  return (
    <Link
      to={path}
      style={{
        textDecoration: "none",
        width: "100%",
      }}
    >
     <HomePageCard
       sx={{
         width: isOtp ? "420px" : "480px",
         height: "180px",
         display: "flex",
         alignItems: "center",
         flexDirection: "row",
         margin: "10px",
         borderRadius: "20px"
       }}
     >
      <Box sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "20px",
      }}
      >
        <Icon fontSize="large"/>
        <Typography
          variant="h5"
          sx={{
            marginLeft: "20px",
            textAlign: "center",
            marginTop: "5px"
          }}
        >
          {children}
        </Typography>
      </Box>
     </HomePageCard>
    </Link>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  isOtp: PropTypes.bool,
  path: PropTypes.string,
  Icon: PropTypes.object,
};

const HomePage = () => {
  const agencyType = useSelector(selectCurrentAgencyType);
  const { hasRole } = useAuth();
  const { t } = useTranslation();
  const isOtp = agencyType === OTP_TYPE;

  return hasRole(SUPERADMIN_ROLE) ? (
    <AdminPage />
  ) : (
   <Box sx={{
     height: "100%",
   }}
  >
    <Typography variant="h4"
      sx={{
        marginLeft: "24px",
        marginTop: "34px"
        }}
    >
      { t("pages.home") }
    </Typography>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
      }}
    >
      <Box
        sx={{
          margin: "50px",
          maxWidth: "1440px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {homePageNavigationLinks.map((link) => (
          <Box key={link.title}>
            {isOtp ? (
              <Card
                isOtp={isOtp}
                path={link.path}
                Icon={link.icon}
              >
              {link.title}
              </Card>
            ) : (
              link.PTP && (
                <Card
                  isOtp={isOtp}
                  path={link.path}
                  Icon={link.icon}
                >
                  {link.title}
                </Card>
              )
            )}
          </Box>
        ))}
      </Box>
    </Box>
   </Box>
  );
};

export default HomePage;
