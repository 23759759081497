import { Button, Typography } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import ReceiptsFilter from "components/Filter/ReceiptsFilter/ReceiptsFilter";
import Filter from "components/Filter/Filter";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import {
  useReceiptListQuery,
  useDeleteReceiptMutation,
} from "features/receipts/receiptsSlice";
import { useFormik } from "formik";
import useReceiptsTable from "hooks/tables/useReceiptsTable";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { getNonEmptyProps } from "util/helpers/objectHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { useNavigate } from "react-router-dom";
import { PAGES } from "constants/pages";
import { useDispatch, useSelector } from "react-redux";
import modalConstants from "constants/modalConstants";
import { closeModal } from "features/modal/modalSlice";

const ReceiptsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modals = useSelector((state) => state.modal.modals);
  const deleteModal = modals?.[modalConstants.DELETE_RECEIPT_MODAL];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ name: "number", direction: "asc" });
  const [filter, setFilter] = useState(null);
  const { columns } = useReceiptsTable({
    page,
    rowsPerPage,
  });

  const { data: receiptList, isLoading: isLoadingReceiptList } =
    useReceiptListQuery({
      page,
      rowsPerPage,
      search,
      sort,
      filter,
    });
  const [deleteReceipt, { isLoading: isLoadingDeleteReceipt }] =
    useDeleteReceiptMutation();

  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
  };

  const filterForm = useFormik({
    initialValues: {
      ReceiptNumber: "",
      ContractNumber: "",
      DateFrom: null,
      DateTo: null,
      Client: "",
    },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleCancelDelete = () => {
    dispatch(closeModal({ id: modalConstants.DELETE_RECEIPT_MODAL }));
  };

  const handleConfirmDelete = () => {
    if (deleteModal?.open) {
      deleteReceipt({ id: deleteModal.data.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteReceipt) {
            dispatch(closeModal({ id: modalConstants.DELETE_RECEIPT_MODAL }));
            makeToastMessage(t("payment.deleteSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("payment.deleteErrorMessage")));
    }
  };

  return (
    <PageContainer>
      <ConfirmModal
        open={deleteModal?.open}
        handleConfirm={handleConfirmDelete}
        handleCancel={handleCancelDelete}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={t("payment.confirmDelete")}
      />
      <Typography variant="h4" color="" style={{ marginBottom: 24 }}>
        {t("payment.receiptsTitle")}
      </Typography>
      <PageHeader>
        <Filter
          filterForm={filterForm}
          handleReset={() => {
            setFilter(null);
            filterForm.resetForm();
          }}
        >
          <ReceiptsFilter filterForm={filterForm} />
        </Filter>
        <Button
          variant="contained"
          onClick={() => navigate(PAGES.ADDRECEIPT.route)}
        >
          {t("payment.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={receiptList?.data}
        isLoading={isLoadingReceiptList}
        count={receiptList?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setSearch={setSearch}
        rowsName={t("payment.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default ReceiptsPage;
