/* eslint-disable no-unused-vars */
import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import CreateIcon from "@mui/icons-material/Create";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "features/modal/modalSlice";
import modalConstants from "constants/modalConstants";
import { PAGES } from "constants/pages";

const useClientTable = ({ page, rowsPerPage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = [
    {
      name: "",
      label: t("tableCols.agency.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "surname",
      label: "Prezime",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Ime",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "address",
      label: "Adresa",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "location",
      label: "Mesto",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "country",
      label: "Država",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "phoneNumber",
      label: "Broj telefona",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "dateOfBirth",
      label: "Datum rođenja",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return format(new Date(rowData.dateOfBirth), "dd.MM.yyyy.");
        },
      },
    },
    {
      name: "passportNumber",
      label: "Broj pasoša",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "passportExpirationDate",
      label: "Datum isteka pasoša",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          if (rowData.passportExpirationDate !== null) {
            return format(
              new Date(rowData.passportExpirationDate),
              "dd.MM.yyyy."
            );
          } else {
            return "";
          }
        },
      },
    },
    {
      name: "updatedOn",
      label: "Poslednje izmene",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return format(new Date(rowData.updatedOn), "dd.MM.yyyy.");
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip
                title="Izmeni"
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    navigate(PAGES.EDITCLIENT.route.replace(":id", rowData.id));
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Obriši" placement="top">
                <IconButton
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: modalConstants.DELETE_CLIENT_MODAL,
                        data: { id: rowData.id },
                      })
                    );
                  }}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useClientTable;
