import StatementForm from "components/Forms/StatementForm/StatementForm";
import Title from "components/Title/Title";
import { useFormik } from "formik";
import statementInitialValues from "initialValues/statementInitialValues";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PageContainer } from "styles/SharedStyle.styled";
import statementValidation from "validations/statementValidation";

const AddEditStatementPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const handleSubmit = (values) => {
    console.log(values);
  };

  const formik = useFormik({
    onSubmit: handleSubmit,
    initialValues: statementInitialValues,
    validationSchema: statementValidation,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  return (
    <PageContainer>
      <Title title={id ? t("statement.editTitle") : t("statement.addTitle")} />
      <StatementForm formik={formik} />
    </PageContainer>
  );
};

export default AddEditStatementPage;
