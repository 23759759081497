import React from "react";

const InvoicePage = () => {
  return (
    <>
      <p>Invoice Page</p>
    </>
  );
};

export default InvoicePage;
