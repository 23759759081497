import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Autocomplete, Box, Button, IconButton } from "@mui/material";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  RowContainer,
} from "styles/SharedStyle.styled";
import { useTranslation } from "react-i18next";
import TextInput from "components/Inputs/TextInput";
import DatePick from "components/Inputs/DatePick";
import SelectInput from "components/Inputs/SelectInput";
import FormActions from "../FormActions/FormActions";
import { useExchangeRateListQuery } from "features/exchange-rate/exchangeRateSlice";
import { convertDateToISO, getOneDayBeforeDate } from "util/dateHelpers";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useClientListQuery } from "features/client/clientApiSlice";
import { useAgencyPartnerListQuery } from "features/agencyPartner/agencyPartnerApiSlice";

const StatementForm = ({ formik }) => {
  const { t } = useTranslation();
  const clientPartners = [
    { value: 1, text: "Partner" },
    { value: 2, text: "Klijent" },
  ];
  const statementTypes = [
    { value: 1, text: "Uplata" },
    { value: -1, text: "Isplata" },
  ];

  const [isClientSelected, setIsClientSelected] = useState(false);
  const [isPartnerSelected, setIsPartnerSelected] = useState(false);

  useEffect(() => {
    const selectedClientPartner = formik.values.items.some(
      (item) => item.clientPartner === 2
    );
    setIsClientSelected(selectedClientPartner);
  }, [formik.values.items]);

  useEffect(() => {
    const selectedClientPartner = formik.values.items.some(
      (item) => item.clientPartner === 1
    );
    setIsPartnerSelected(selectedClientPartner);
  }, [formik.values.items]);

  const { data: clients, isLoading: isLoadingClients } = useClientListQuery(
    { page: 0, rowsPerPage: 1000 },
    { skip: !isClientSelected }
  );
  const { data: partners, isLoading: isLoadingPartners } = useAgencyPartnerListQuery(
    {
      page: 0,
      rowsPerPage: 1000,
    },
    { skip: !isPartnerSelected }
  );

  // create a list of clients that contain value and text
  const clientList = !isLoadingClients && clients?.data.map((client) => ({
    value: client.id,
    text: `${client.name} ${client.surname}, ${client.address}`,
  })) || [];

  const partnerList = !isLoadingPartners && partners?.data.map((partner) => ({
    value: partner.id,
    text: partner.name,
  })) || [];

  const { data: exchangeRate, isLoading: isLoadingExchangeRate } =
    useExchangeRateListQuery(
      {
        // one day before the statement date
        page: 0,
        rowsPerPage: 1,
        filter: {
          OnDate: convertDateToISO(getOneDayBeforeDate(formik.values.date)),
        },
      },
      { skip: !formik.values.date }
    );

  // when date is changed, change exchange rate
  React.useEffect(() => {
    if (!isLoadingExchangeRate && exchangeRate?.data?.length) {
      formik.values.items.map((item, index) => {
        formik.setFieldValue(
          `items[${index}].exchangeRate`,
          exchangeRate.data[0].rate
        );
      });
    } else {
      formik.values.items.map((item, index) => {
        formik.setFieldValue(`items[${index}].exchangeRate`, "");
      });
    }
  }, [formik.values.date, isLoadingExchangeRate, exchangeRate]);

  return (
    <Box component="form">
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("statement.sections.basicInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer>
          <TextInput
            fullWidth
            name="number"
            label={t("field.statementNumberPlaceholderRequired")}
            value={formik.values.number || ""}
            onChange={formik?.handleChange}
            error={formik.touched.number && Boolean(formik.errors.number)}
            helperText={formik?.touched.number && formik?.errors.number}
            style={{ marginRight: "24px" }}
          />
          <DatePick
            name="date"
            label={t("field.statementDatePlaceholderRequired")}
            value={formik.values.date}
            onChange={(value) => formik.setFieldValue("date", value)}
            style={{ marginRight: "24px" }}
            error={formik.touched.date && Boolean(formik.errors.date)}
            helperText={formik.touched.date && formik.errors.date}
          />
          <TextInput
            fullWidth
            name="accountNumber"
            label={t("field.accountNumberPlaceholderRequired")}
            value={formik.values.accountNumber || ""}
            onChange={formik?.handleChange}
            error={
              formik.touched.accountNumber &&
              Boolean(formik.errors.accountNumber)
            }
            helperText={
              formik?.touched.accountNumber && formik?.errors.accountNumber
            }
          />
        </RowContainer>
      </FormContainer>
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("statement.sections.statementItemsInfo")}
          </InformationMainText>
        </InformationContainer>
        <>
          {formik?.values.items &&
            formik.values.items.map((item, index) => (
              <RowContainer
                key={index}
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                <TextInput
                  label="R.br."
                  value={index + 1}
                  style={{ marginRight: "24px", width: 700 }}
                  disabled
                />
                <SelectInput
                  fullWidth
                  name="creditDebit"
                  label={t("field.statementTypePlaceholderRequired")}
                  value={formik.values.items[index].creditDebit || ""}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `items[${index}].creditDebit`,
                      parseInt(e.target.value)
                    )
                  }
                  error={
                    formik.touched.items?.[index]?.creditDebit &&
                    Boolean(formik.errors.items?.[index]?.creditDebit)
                  }
                  helperText={
                    formik.touched.items?.[index]?.creditDebit &&
                    formik.errors.items?.[index]?.creditDebit
                  }
                  style={{ marginRight: "24px" }}
                  items={statementTypes}
                />
                <SelectInput
                  fullWidth
                  name="clientPartner"
                  label={t("field.clientPartnerPlaceholderRequired")}
                  value={formik.values.items[index].clientPartner || ""}
                  onChange={(e) => {
                    formik.setFieldValue(
                      `items[${index}].clientPartner`,
                      e.target.value
                    );
                    formik.setFieldValue(`items[${index}].clientId`, "");
                  }}
                  style={{ marginRight: "24px" }}
                  items={clientPartners}
                />

                <Autocomplete
                  fullWidth
                  style={{ marginRight: "24px" }}
                  id="clientId"
                  options={
                    formik.values.items[index].clientPartner === 1
                      ? partnerList
                      : formik.values.items[index].clientPartner === 2
                      ? clientList
                      : []
                  }
                  getOptionLabel={(option) => option.text}
                  value={
                    formik.values.items[index].clientPartner === 1
                      ? partnerList?.find(
                          (option) =>
                            option.value === formik.values.items[index].clientId
                        )
                      : formik.values.items[index].clientPartner
                      ? clientList?.find(
                          (option) =>
                            option.value === formik.values.items[index].clientId
                        )
                      : null
                  }
                  componentsProps={{
                    popper: { style: { width: "fit-content" } },
                  }}
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      `items[${index}].clientId`,
                      newValue ? newValue.value : ""
                    );
                  }}
                  renderInput={(params) => (
                    <TextInput
                      {...params}
                      name="clientId"
                      label={
                        formik.values.items[index].clientPartner === 1
                          ? t("field.partnerPlaceholderRequired")
                          : formik.values.items[index].clientPartner === 2
                          ? t("field.clientPlaceholderRequired")
                          : ""
                      }
                      error={
                        formik.touched.items?.[index]?.clientId &&
                        Boolean(formik.errors.items?.[index]?.clientId)
                      }
                      helperText={
                        formik.touched.items?.[index]?.clientId &&
                        formik.errors.items?.[index]?.clientId
                      }
                    />
                  )}
                />

                <TextInput
                  fullWidth
                  name="invoiceNumber"
                  label={t("field.invoiceNumberPlaceholderRequired")}
                  value={formik.values.items[index].invoiceNumber || ""}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `items[${index}].invoiceNumber`,
                      e.target.value
                    )
                  }
                  style={{ marginRight: "24px" }}
                  error={
                    formik.touched.items?.[index]?.invoiceNumber &&
                    Boolean(formik.errors.items?.[index]?.invoiceNumber)
                  }
                  helperText={
                    formik.touched.items?.[index]?.invoiceNumber &&
                    formik.errors.items?.[index]?.invoiceNumber
                  }
                />

                <TextInput
                  fullWidth
                  name="reservationNumber"
                  label={t("field.reservationNumberPlaceholder")}
                  value={formik.values.items[index].reservationNumber || ""}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `items[${index}].reservationNumber`,
                      e.target.value
                    )
                  }
                  style={{ marginRight: "24px" }}
                />

                <TextInput
                  fullWidth
                  name="description"
                  label={t("field.statementDescriptionPlaceholder")}
                  value={formik.values.items[index].description || ""}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `items[${index}].description`,
                      e.target.value
                    )
                  }
                  style={{ marginRight: "24px" }}
                />
                <TextInput
                  fullWidth
                  name="totalAmount"
                  type="number"
                  label={t("field.totalAmountPlaceholderRequired")}
                  value={formik.values.items[index].totalAmount || ""}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `items[${index}].totalAmount`,
                      parseFloat(e.target.value)
                    )
                  }
                  error={
                    formik.touched.items?.[index]?.totalAmount &&
                    Boolean(formik.errors.items?.[index]?.totalAmount)
                  }
                  helperText={
                    formik.touched.items?.[index]?.totalAmount &&
                    formik.errors.items?.[index]?.totalAmount
                  }
                  style={{ marginRight: "24px" }}
                />
                <TextInput
                  fullWidth
                  name="amount"
                  type="number"
                  label={t("field.amountPlaceholder")}
                  value={formik.values.items[index].amount || ""}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `items[${index}].amount`,
                      parseFloat(e.target.value)
                    )
                  }
                  style={{ marginRight: "24px" }}
                />
                <TextInput
                  fullWidth
                  name="foreignAmount"
                  type="number"
                  label={t("field.foreignAmountPlaceholder")}
                  value={formik.values.items[index].foreignAmount || ""}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `items[${index}].foreignAmount`,
                      parseFloat(e.target.value)
                    )
                  }
                  style={{ marginRight: "24px" }}
                />
                <TextInput
                  fullWidth
                  name="exchangeRate"
                  type="number"
                  label={t("field.ratePlaceholderRequired")}
                  value={formik.values.items[index].exchangeRate || ""}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `items[${index}].exchangeRate`,
                      parseFloat(e.target.value)
                    )
                  }
                  error={
                    formik.touched.items?.[index]?.exchangeRate &&
                    Boolean(formik.errors.items?.[index]?.exchangeRate)
                  }
                  helperText={
                    formik.touched.items?.[index]?.exchangeRate &&
                    formik.errors.items?.[index]?.exchangeRate
                  }
                />
                <IconButton
                  color="error"
                  onClick={() => {
                    if (formik.values.items.length > 1) {
                      formik.setFieldValue(
                        "items",
                        formik.values.items.filter((_, i) => i !== index) // Remove the item
                      );
                    } else {
                      formik.setFieldValue("items", [
                        {
                          creditDebit: "",
                          amount: "",
                          foreignAmount: "",
                          totalAmount: "",
                          description: "",
                          exchangeRate: "",
                          clientPartner: "",
                          clientId: "",
                          invoiceNumber: "",
                          reservationNumber: "",
                        },
                      ]);
                    }
                  }}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </RowContainer>
            ))}
          <Box style={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() =>
                formik.setFieldValue("items", [
                  ...formik.values.items,
                  {
                    creditDebit: "",
                    amount: "",
                    foreignAmount: "",
                    totalAmount: "",
                    description: "",
                    exchangeRate: "",
                    clientPartner: "",
                    clientId: "",
                    invoiceNumber: "",
                    reservationNumber: "",
                  },
                ])
              }
            >
              {t("statement.addNewItem")}
            </Button>
          </Box>
        </>
      </FormContainer>
      <FormActions
        disabled={!formik.dirty}
        handleConfirm={formik.handleSubmit}
        handleCancel={formik.resetForm}
      />
    </Box>
  );
};

StatementForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  id: PropTypes.string,
  isAdd: PropTypes.bool.isRequired,
};

export default StatementForm;
