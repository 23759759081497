/* eslint-disable no-unused-vars */
import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import CreateIcon from "@mui/icons-material/Create";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { openModal } from "features/modal/modalSlice";
import modalConstants from "constants/modalConstants";
import { PAGES } from "constants/pages";

const usePartnersTable = ({ page, rowsPerPage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = [
    {
      name: "",
      label: t("tableCols.partner.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "partnerOnly",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "name",
      label: t("tableCols.partner.partnerNameLabel"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "agencyType",
      label: t("tableCols.partner.partnerTypeLabel"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "address",
      label: t("tableCols.partner.addressLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "location",
      label: t("tableCols.partner.cityLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "country",
      label: t("tableCols.partner.countryLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "pib",
      label: t("tableCols.partner.pibLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "legalIdentificationNumber",
      label: t("tableCols.partner.legalIdentificationNumberLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "email",
      label: t("tableCols.partner.emailLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          const partner = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex" }}>
              {partner.partnerOnly ? (
                <Tooltip
                  title={t("tableCols.agency.tooltip.details")}
                  placement="top"
                  style={{ marginRight: 5 }}
                >
                  <IconButton
                    onClick={() => {
                      navigate(
                        PAGES.EDITPARTNER.route.replace(":id", partner.id)
                      );
                    }}
                  >
                    <CreateIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <div style={{ width: 40, marginRight: 5 }}></div>
              )}
              <Tooltip
                title={t("tableCols.user.tooltip.deleteUser")}
                placement="top"
              >
                <IconButton
                  onClick={() => {
                    dispatch(
                      openModal({
                        id: modalConstants.DELETE_PARTNER_MODAL,
                        data: {
                          id: partner.id,
                          isPartnerOnly: partner.partnerOnly,
                        },
                      })
                    );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];
  return { columns };
};

export default usePartnersTable;
