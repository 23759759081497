export default {
    name: "",
    agencyTypeId: "",
    email: "",
    address: "",
    locationId: "",
    countryId: "",
    phoneNumber: "",
    pib: "",
    legalIdentificationNumber: ""
  };
  