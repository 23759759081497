import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PageContainer } from "styles/SharedStyle.styled";
import {
  useAddUserMutation,
  useEditUserMutation,
  useUserDetailsQuery,
} from "features/user/userApiSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PAGES } from "constants/pages";
import Title from "components/Title/Title";
import UserForm from "components/Forms/UserForm/UserForm";
import editUserValidation from "validations/editUserValidation";
import addUserValidation from "validations/addUserValidation";
import { useFormik } from "formik";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import addUserInitialValues from "initialValues/addUserInitialValues";
import { useSelector } from "react-redux";
import { selectCurrentAgency } from "features/auth/authSlice";
import useAuth from "hooks/useAuth";
import BackdropComponent from "components/Backdrop/BackdropComponent";
import Error from "pages/ErrorPage/ErrorPage";

const AddEditUserPage = () => {
  const { id } = useParams();
  let { state } = useLocation();
  const { isSuperAdmin } = useAuth();
  const agencyId = useSelector(selectCurrentAgency);
  const path = useLocation().pathname;
  const isAdd = isSuperAdmin
    ? path === PAGES.ADDUSERADMIN.route
    : path === PAGES.ADDUSER.route;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [addUser, { isLoading: isLoadingAddUser }] = useAddUserMutation();
  const [editUser, { isLoading: isLoadingEditUser }] = useEditUserMutation();
  const {
    data: userDetails,
    isLoading: isLoadingUserDetails,
    error,
  } = useUserDetailsQuery({ id }, { skip: isAdd });

  const handleSubmit = (val) => {
    if (isAdd) {
      addUser({
        ...val,
        travelAgencyId: isSuperAdmin
          ? Number(state?.addAgencyId)
          : Number(agencyId),
      })
        .unwrap()
        .then(() => {
          if (!isLoadingAddUser) {
            isSuperAdmin
              ? navigate(PAGES.AGENCY.route.replace(":id", state?.agencyId), {
                  state: { tabId: 1 },
                })
              : navigate(PAGES.SETTINGS.route, { state: { tabId: 1 } });
            makeToastMessage(t("users.addSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("users.addErrorMessage")));
    }

    if (id) {
      editUser({
        id: Number(id),
        travelAgencyId: isSuperAdmin
          ? Number(state?.editAgencyId)
          : Number(agencyId),
        roleId: val.roleId,
        name: val.name,
        surname: val.surname,
        phoneNumber: val.phoneNumber,
        office: val.office,
        email: val.email,
      })
        .unwrap()
        .then(() => {
          if (!isLoadingEditUser) {
            isSuperAdmin
              ? navigate(
                  PAGES.AGENCY.route.replace(":id", state?.editAgencyId),
                  { state: { tabId: 1 } }
                )
              : navigate(PAGES.SETTINGS.route, { state: { tabId: 1 } });
            makeToastMessage(t("users.editSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("users.editErrorMessage")));
    }
  };

  const addEditUserFormik = useFormik({
    initialValues:
      id && !isLoadingUserDetails ? userDetails : addUserInitialValues,
    validationSchema: id ? editUserValidation : addUserValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  if (isLoadingUserDetails) {
    return (
      <BackdropComponent position="absolute" isLoading={isLoadingUserDetails} />
    );
  }

  if (error) {
    if (error.status === 400) {
      return <Error />;
    }
  }

  return (
    <PageContainer>
      <Title title={isAdd ? t("users.addTitle") : t("users.editTitle")} />
      <UserForm formik={addEditUserFormik} isAdd={isAdd} />
    </PageContainer>
  );
};
AddEditUserPage.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object,
    errors: PropTypes.object,
    dirty: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
  }).isRequired,
};
export default AddEditUserPage;
