import React from "react";
import { codebookPageNavigationLinks } from "constants/navigationConstants";
import { HomePageCard } from "../HomePage/HomePage.styled";
import { Box, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CodebookPage = () => {

  const { t } = useTranslation();

    return (
    <Box sx={{ padding: 2  }}>
      <Typography variant="h4"
       sx={{
         marginLeft: "14px",
         marginTop: "10px"
         }}
       >
      { t("pages.codebook") }
     </Typography>
      <Grid container spacing={2} style={{ maxWidth: "1200px", margin: "20px auto", padding: "0 20px" }}>
        {codebookPageNavigationLinks.map((link) => {
          const IconComponent = link.icon

          return (
            <Grid item xs={12} sm={6} key={link.path}>
              <Link
                to={link.path}
                style={{
                  textDecoration: "none",
                  width: "100%",
                  display: "block",

                }}
              >
                <HomePageCard
                  sx={{
                    height: "180px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    borderRadius: "20px",
                    width: "100%", // Make the card take full width of its grid item
                  }}
                >
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "20px",
                    width: "100%", // Ensure the content takes full width
                  }}
                  >
                    <IconComponent fontSize="large"/>
                    <Typography
                      variant="h5"
                      sx={{
                        marginLeft: "20px",
                        textAlign: "center",
                        marginTop: "5px"
                      }}
                    >
                      {link.title}
                    </Typography>
                  </Box>
                </HomePageCard>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
 };

export default CodebookPage;
