export default {
  app: {
    title: "Master of Travel",
  },
  field: {
    contractNumberPlaceholder: "Broj Ugovora",
    travelDatePlaceholder: "Datum putovanja",
    clientSurnamePlaceholder: "Klijent (prezime)",
    surnamePlaceholder: "Prezime*",
    surnameRequired: "Prezime je obavezno polje",
    namePlaceholder: "Ime*",
    name: "Ime",
    countryPlaceholder: "Država",
    locationPlaceholder: "Mesto",
    surname: "Prezime",
    userStatus: "Status korisnika",
    nameRequired: "Ime je obavezno polje",
    passwordPlaceholder: "Lozinka*",
    passwordRequired: "Lozinka je obavezno polje",
    passwordMin: "Unesite minimum 8 karaktera",
    passwordMatch: "Lozinke se ne poklapaju",
    confirmPasswordPlaceholder: "Potvrda lozinke*",
    confirmPasswordRequired: "Potvrda lozinke je obavezno polje",
    agencyNameFilterPlaceholder: "Naziv agencije",
    agencyNamePlaceholder: "Naziv*",
    agencyNameRequired: "Naziv je obavezno polje",
    partnerPlaceholder: "Naziv partnera",
    partnerNamePlaceholder: "Naziv*",
    partnerNameRequired: "Naziv je obavezno polje",
    partnerTypePlaceholder: "Tip partnera",
    partnerStatusPlaceholder: "Status partnera",
    agencyTypePlaceholder: "Tip agencije",
    agencyTypeIdPlaceholder: "Tip agencije*",
    agencyTypeIdRequired: "Tip agencije je obavezno polje",
    partnerTypeIdPlaceholder: "Tip partnera*",
    partnerTypeIdRequired: "Tip partnera je obavezno polje",
    addressPlaceholder: "Adresa*",
    addressRequired: "Adresa je obavezno polje",
    locationIdPlaceholder: "Mesto*",
    locationIdRequired: "Mesto je obavezno polje",
    locationNamePlaceholder: "Naziv mesta*",
    locationRequired: "Mesto je obavezno polje",
    countryNamePlaceholder: "Naziv države*",
    countryIdPlaceholder: "Država*",
    countryIdRequired: "Država je obavezno polje",
    countryRequired: "Naziv države je obavezno polje",
    emailPlaceholder: "Email*",
    emailNoRequiredPlaceholder: "Email",
    emailRequired: "Email je obavezno polje",
    emailFormat: "Email format nije validan",
    phoneNumberRequiredPlaceholder: "Broj telefona*",
    phoneNumberPlaceholder: "Broj telefona",
    phoneNumberRequired: "Broj telefona je obavezno polje",
    webAddressPlaceholder: "Web adresa",
    pibPlaceholder: "PIB*",
    pibRequired: "PIB je obavezno polje",
    pibLength: "Dužina PIB-a mora biti tačno 9 cifara",
    legalIdentificationNumberPlaceholder: "Matični broj",
    legalIdentificationNumberPlaceholderRequired: "Matični broj*",
    legalIdentificationNumberRequired: "Matični broj firme je obavezno polje",
    legalIdentificationNumberLength:
      "Dužina matičnog broja mora biti tačno 8 cifara",
    accountNumberPlaceholder: "Broj tekućeg računa*",
    accountNumberRequired: "Broj tekućeg računa je obavezno polje",
    personInChargePlaceholder: "Odgovorno lice",
    personInChargeNameRequired: "Odgovorno lice je obavezno polje",
    systemLicenceStatusPlaceholder: "Status sistemske licence",
    systemLicenceExpirationDatePlaceholder: "Datum isteka sistemske licence",
    licenceNumberPlaceholder: "Broj licence*",
    licenceNumberRequired: "Broj licence je obavezno polje",
    licenceNumberExpirationDatePlaceholder: "Datum isteka licence*",
    licenceNumberExpirationDateRequired:
      "Datum isteka licence je obavezno polje",
    licenceNumberExpirationDateTypeError: "Neispravan format datuma",
    roleIdPlaceholder: "Rola",
    roleIdRequiredPlaceholder: "Rola*",
    roleIdRequired: "Rola je obavezno polje",
    officePlaceholder: "Poslovnica",
    systemLicenceNumberPlaceholder: "Broj licence/ID*",
    systemLicenceNumberRequired: "Broj licence/ID je obavezno polje",
    systemLicenceStartDatePlaceholder: "Datum aktiviranja licence*",
    systemLicenceStartDateRequired:
      "Datum aktiviranja licence je obavezno polje",
    systemLicenceEndDatePlaceholder: "Datum isteka licence*",
    systemLicenceEndDateRequired: "Datum isteka licence je obavezno polje",
    systemLicenceStatusIdPlaceholder: "Status licence*",
    systemLicenceStatusIdRequired: "Status licence je obavezno polje",
    agencyLogoPlaceholder: "Logo agencije",
    signaturePlaceholder: "Potpis ovlašćenog lica",
    accommodationTypeIdPlaceholder: "Tip objekta",
    accommodationTypeIdPlaceholderRequired: "Tip objekta*",
    accommodationUnitCharacteristicsPlaceholder:
      "Naziv karakteristike smeštajne jedinice*",
    accommodationUnitCharacteristicsRequired:
      "Naziv karakteristike smeštajne jedinice je obavezno polje",
    accommodationTypePlaceholder: "Naziv tipa objekta*",
    accommodationTypeRequired: "Naziv tipa objekta je obavezno polje",
    accommodationTypeIdRequired: "Tip objekta je obavezno polje",
    accommodationPlaceholder: "Naziv objekta*",
    accommodationRequired: "Naziv objekta je obavezno polje",
    accommodationUnitTypePlaceholder: "Naziv vrste smeštajne jedinice*",
    accommodationUnitTypeRequired:
      "Naziv vrste smeštajne jedinice je obavezno polje",
    categoryIdPlaceholder: "Kategorija putovanja",
    categoryPlaceholderRequired: "Kategorija putovanja*",
    categoryPlaceholder: "Naziv kategorije putovanja*",
    categoryRequired: "Naziv kategorije putovanja je obavezno polje",
    servicesPlaceholder: "Naziv usluge*",
    serviceIdPlaceholder: "Vrsta usluge",
    serviceIdPlaceholderRequired: "Vrsta usluge*",
    servicesRequired: "Naziv usluge je obavezno polje",
    dateOfBirthPlaceholder: "Datum rođenja*",
    dateOfBirthRequired: "Datum rođenja je obavezno polje",
    passportNumberPlaceholder: "Broj pasoša",
    passportNumberMinLength: "Broj pasoša mora biti tačno 9 cifara",
    passportExpirationDatePlaceholder: "Datum isteka pasoša",
    dateFormatTypeError: "Pogrešan format datuma",
    agencyStatusPlaceholder: "Status agencije",
    transportationTypePlaceholder: "Vrsta prevoza",
    transportatopnTypeIdPlaceholderRequired: "Vrsta prevoza*",
    transportationTypeRequired: "Vrsta prevoza je obavezno polje",
    offerTypePlaceholder: "Tip ponude",
    offerTypePlaceholderRequired: "Tip ponude*",
    offerTypeRequired: "Tip ponude je obavezno polje*",
    accommodationUnitNamePlaceholder: "Naziv smeštajne jedinice*",
    accommodationUnitTypePlaceholderFilter: "Vrsta smeštajne jedinice",
    accommodationUnitTypeRequiredPlaceholder: "Vrsta smeštajne jedinice*",
    accommodationUnitDescriptionPlaceholder: "Opis smeštajne jedinice",
    unitPropertyIdPlaceholder: "Karakteristike smeštajne jedinice*",
    unitPropertyIdPlaceholderRequired: "Karakteristike smeštajne jedinice*",
    travelProgramPlaceholder: "Program putovanja",
    descriptionPlaceholder: "Napomena",
    accommodationIdPlaceholderRequired: "Objekat*",
    accommodationIdPlaceholder: "Objekat",
    jidPlaceholder: "JID broj",
    travelOrganizerPlaceholder: "Organizator",
    travelOrganizerPlaceholderRequired: "Organizator*",
    offerNumberPlaceholderRequired: "Broj ponude*",
    offerNamePlaceholder: "Naziv ponude",
    offerNamePlaceholderRequired: "Naziv ponude*",
    accommodationIdRequired: "Objekat je obavezno polje",
    categoryIdRequired: "Kategorija putovanja je obavezno polje",
    serviceIdRequired: "Vrsta usluge je obavezno polje",
    transportationTypeIdRequired: "Vrsta prevoza je obavezno polje",
    travelOrganizerIdRequired: "Organizator putovanja je obavezno polje",
    offerTypeIdRequired: "Tip ponude je obavezno polje",
    offerNameRequired: "Naziv ponude je obavezno polje",
    offerStatusPlaceholder: "Status ponude",
    offerNumberRequired: "Broj ponude je obavezno polje",
    jidRequired: "JID je obavezno polje",
    accommodationUnitTypeIdRequired:
      "Vrsta smeštajne jedinice je obavezno polje",
    unitPropertyIdRequired:
      "Karakteristike smeštajne jedinice su obavezno polje",
    oldPasswordPlaceholder: "Stara lozinka*",
    oldPasswordRequired: "Stara lozinka je obavezno polje",
    newPasswordPlaceholder: "Nova lozinka*",
    newPasswordRequired: "Nova lozinka je obavezno polje",
    passwordUpperCaseRequired: "Lozinka mora da sadrži bar jedno veliko slovo",
    passwordSpecialCharRequired:
      "Lozinka mora da sadrži bar jedan specijalni karakter",
    genderIdPlaceholder: "Pol*",
    genderIdRequired: "Pol je obavezno polje",
    passportImagePlaceholder: "Slika pasoša",
    profileImage: "Slika profila",
    receiptDate: "Datum priznanice",
    contractNumber: "Broj Ugovora*",
    receiptNumber: "Broj priznanice",
    client: "Klijent (prezime, ime)",
    dateFrom: "Datum priznanice: izaberi od",
    dateTo: "Datum priznanice: izaberi do",
    contractNumberRequired: "Broj Ugovora je obavezno polje",
    dateOfTermRequired: "Datum termina je obavezno polje",
    clientNameAndSurnamePlaceholder: "Prezime i ime klijenta*",
    travelDescriptionPlaceholder: "Opis*",
    cashAmountPlaceholder: "Način plaćanja - gotovina",
    cardAmountPlaceholder: "Način plaćanja - kartica",
    checkAmountPlaceholder: "Način plaćanja - ček",
    termDate: "Datum Priznanice*",
    accommodation: "Objekat*",
    nbsCourse: "Kurs NBS*",
    nbsCurrency: "Valuta*",
    totalAmount: "Ukupan iznos",
    restAmount: "Ostalo za uplatu",
    offerDetailsLevelPlaceholder: "Nivo detalja ponude*",
    offerDetailsLevelRequired: "Nivo detalja ponude je obavezno polje",
    descriptionRequired: "Opis je obavezno polje",
    totalAmountRequired: "Ukupan iznos je obavezno polje",
    accomodationRequired: "Objekat je obavezno polje",
    cashAmountRequired: "Način plaćanja - gotovina je obavezno polje",
    cardAmountRequired: "Način plaćanja - kartica je obavezno polje",
    checkAmountRequired: "Način plaćanja -ček je obavezno polje",
    nbsCourseRequired: "Kurs NBS je obavezno polje",
    nbsCurrencyRequired: "Valuta je obavezno polje",
    restAmountRequired: "Razlika je obavezno polje",
    dateCalendar: "Datum*",
    currency: "Valuta*",
    middleCourse: "Srednji kurs NBS*",
    dateFilter: "Datum",
    currencyFilter: "Valuta",
    accountNumberFilter: "Broj bank. računa",
    statementNumberFilter: "Broj izvoda",
    statementDescriptionPlaceholder: "Opis",
    ratePlaceholderRequired: "Kurs*",
    totalAmountPlaceholderRequired: "Ukupan iznos*",
    amountPlaceholder: "Dinarski deo",
    foreignAmountPlaceholder: "Devizni deo",
    statementNumberPlaceholderRequired: "Broj izvoda*",
    statementNumberRequireduired: "Broj izvoda je obavezno polje",
    statementDateRequired: "Datum izvoda je obavezno polje",
    statementAccountNumberRequired: "Broj bankovnog računa je obavezno polje",
    creditDebitRequired: "Vrsta izvoda je obavezno polje",
    clientIdRequired: "Klijent/Partner je obavezno polje",
    invoiceNumberRequired: "Broj fakture je obavezno polje",
    exchangeRateRequired: "Kurs je obavezno polje",
    statementDatePlaceholderRequired: "Datum*",
    accountNumberPlaceholderRequired: "Broj bankovnog računa*",
    statementTypePlaceholderRequired: "Vrsta izvoda*",
    clientPartnerPlaceholderRequired: "Klijent/Partner*",
    clientPlaceholderRequired: "Naziv klijenta*",
    partnerPlaceholderRequired: "Naziv partnera*",
    invoiceNumberPlaceholderRequired: "Broj fakture*",
    reservationNumberPlaceholder: "Broj ugovora",
    middleCourseRequired: "Srednji kurs NBS je obavezno polje",
    currencyDateRequired: "Datum kursa je obavezno polje",
    passengerNamePlaceholderRequired: "Prezime i Ime*",
    passengerNameRequired: "Prezime i Ime je obavezno polje",
    departurePlacePlaceholder: "Mesto polaska*",
    departurePlaceRequired: "Mesto polaska je obavezno polje",
    departureDatePlaceholder: "Datum polaska*",
    departureDateRequired: "Datum polaska je obavezno polje",
    appointmentDatePlaceholder: "Termin*",
    quantityRequired: "Količina je obavezno polje",
    positiveNumber: "Unesite pozitivan broj",
    calculationPriceDinRequired: "Cena dinarski deo je obavezno polje",
    calculationPriceEurRequired: "Cena devizni deo je obavezno polje",
    calculationSubTotalRequired: "Ukupna suma je obavezno polje",
    calculationSaleDinRequired: "Popust na dinarski deo je obavezno polje",
    calculationSaleEurRequired: "Popust na devizni deo je obavezno polje",
    calculationTotalRequired: "Iznos za uplatu je obavezno polje",
    minNumber: "Unesite broj veći od 0",
    atLeastOneAmountRequired: "Najmanje jedan iznos je obavezno polje",
    postalCodePlaceholder: "Poštanski broj",
  },
  common: {
    all: "Sve",
    userActive: "Aktivan",
    userInactive: "Neaktivan",
    apply: "Primeni",
    filter: "Filter",
    language: "Jezik",
    english: "Engleski",
    serbian: "Srpski",
    dataGridExample: "Primer Data Grid-a",
    close: "Zatvori",
    trademark: "TM",
    search: "Pretraga",
    error: "Greška",
    continue: "Nastavite",
    labelUsername: "Korisničko ime",
    labelEmail: "E-mail",
    labelPassword: "Lozinka",
    next: "Napred",
    nextPage: "Sledeća stranica",
    previousPage: "Predhodna stranica",
    back: "Nazad",
    goBack: "Idite nazad",
    ok: "U redu",
    done: "Gotovo",
    confirm: "Potvrdi",
    printDownload: "Print/Download",
    cancel: "Odustani",
    remove: "Remove",
    invite: "Invite",
    save: "Sačuvaj",
    complete: "Complete",
    download: "Download",
    yes: "Yes",
    no: "No",
    to: "to",
    select: "Select...",
    none: "None",
    reserve: "Rezerviši",
    date: {
      range: "{{start}} do {{end}}",
    },
    logout: "Odjavi me",
    seeMore: "Vidi još",
    showProfile: "Prikaži profil",
    choosePhoto: "Odaberite sliku",
    pictureSize: "Maksimalna veličina: {{size}}MB",
    resetFilters: "Poništi filtere",
  },

  notifications: {
    title: "Obaveštenja",
  },
  pages: {
    home: "Početna",
    login: "Login",
    register: "Register",
    forgotPassword: "Zaboravljena lozinka",
    error: "Pogrešna stranica",
    notFound: "Nije pronađena stranica",
    profile: "Profilna stranica",
    settings: "Podešavanja",
    dashboard: "Komandna tabla",
    clients: "Klijenti",
    codebook: "Šifarnici",
    partners: "Partneri",
    offers: "Ponude",
    sales: "Prodaja",
    internalSale: "Interna",
    externalSale: "Eksterna",
    contracts: "Ugovori",
    payment: "Naplata",
    excerpts: "Izvodi",
    reports: "Izveštaji",
    notifications: "Notifikacije",
    travelCategory: "Kategorija putovanja",
    country: "Država",
    city: "Mesto",
    objectType: "Tip objekta",
    object: "Objekat",
    accommodationUnit: "Smeštajne jedinice",
    accommodationUnitType: "Vrsta smeštajne jedinice",
    accomodationUnitCharacteristics: "Karakteristike smeštajne jedinice",
    serviceType: "Vrste usluga",
    transportationType: "Vrsta prevoza",
    offerType: "Tip ponude",
    receipts: "Priznanice",
    invoice: "Faktura",
    proinvoice: "Profaktura",
    charge: "Zaduženje",
    exchangeRate: "Kursna lista",
  },
  dropdown: {
    agencyType: "Odaberite tip agencije",
    country: "Odaberite državu",
    location: "Odaberite mesto",
    activeOffer: "Aktivne ponude",
    inactiveOffer: "Neaktivne ponude",
    currency: "Valuta",
  },
  register: {
    registerTitle: "Registracija",
    usernameRequired: "Email je obavezno polje.",
    emailFormat: "Pogrešan format email-a.",
    emailRequired: "An email or username is required.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    passwordRequired: "Lozinka je obavezno polje.",
  },
  login: {
    welcome: "React template",
    dontHaveAccount: "Nemate nalog? ",
    emailFormat: "Pogrešan format email-a",
    emailRequired: "Email je obavezno polje",
    noUsers: "Ne postoji korisnik",
    passwordStrength: "Your password is {{strength}}.",
    passwordLength: "Dužina šifre ne sme biti manja od 8 karaktera",
    signUpRecommendation: "Registrujte se",
    email: "Please enter your email address or username to log in:",
    logInTitle: "Ulogujte se u svoj nalog",
    logIn: "Ulogujte se",
    signUp: "Sign Up",
    usernameRequired: "Username is required.",
    passwordRequired: "Lozinka je obavezno polje",
    forgotYourPassword: "Zaboravili ste šifru?",
    forgotPasswordEmail: "Email",
    useDifferentEmail: "Use different email address or username",
  },
  contract: {
    pageTitle: "Ugovori",
    editTitle: "Izmena ugovora",
    confirmClose: "Da li želite da napustite izmenu ugovora?",
    confirmDelete: "Da li ste sigurni da želite da obrišete ugovor?",
    rowsName: "ugovora",
    editSuccessMessage: "Uspešno ste izmenili ugovor",
    editErrorMessage: "Greška prilikom izmene ugovora",
    deleteSuccessMessage: "Uspešno ste obrisali ugovor",
    deleteErrorMessage: "Greška prilikom uklanjanja ugovora",
  },
  singleContract: {
    pageTitle: "Ugovor o putovanju broj: {{contractNumber}}",
    addPassenger: "Dodaj putnika",
    sections: {
      travelDetails: "Detalji putovanja",
      passengersDetails: "Podaci putnika",
      calculation: "Obračun",
      description: "Napomena",
    },
  },
  category: {
    pageTitle: "Kategorija putovanja",
    searchPlaceholder: "Pretraga kategorije putovanja",
    addButton: "Dodaj kategoriju putovanja",
    editTitle: "Izmena kategorije putovanja",
    addTitle: "Dodavanje kategorije putovanja",
    confirmClose: "Da li želite da napustite izmenu kategorije putovanja?",
    confirmDelete:
      "Da li ste sigurni da želite da obrišete kategoriju putovanja?",
    rowsName: "kategorija putovanja",
    addSuccessMessage: "Uspešno ste dodali novu kategoriju putovanja",
    addErrorMessage: "Greška prilikom dodavanja nove kategorije putovanja",
    editSuccessMessage: "Uspešno ste izmenili kategoriju putovanja",
    editErrorMessage: "Greška prilikom izmene kategorije putovanja",
    deleteSuccessMessage: "Uspešno ste obrisali kategoriju putovanja",
    deleteErrorMessage: "Greška prilikom uklanjanja kategorije putovanja",
  },
  country: {
    pageTitle: "Država",
    searchPlaceholder: "Pretraga država",
    addButton: "Dodaj državu",
    editTitle: "Izmena države",
    addTitle: "Dodavanje države",
    confirmClose: "Da li želite da napustite izmenu države?",
    confirmDelete: "Da li ste sigurni da želite da obrišete državu?",
    rowsName: "država",
    addSuccessMessage: "Uspešno ste dodali novu državu",
    addErrorMessage: "Greška prilikom dodavanja nove države",
    editSuccessMessage: "Uspešno ste izmenili državu",
    editErrorMessage: "Greška prilikom izmene države",
    deleteSuccessMessage: "Uspešno ste obrisali državu",
    deleteErrorMessage: "Greška prilikom uklanjanja države",
  },
  location: {
    pageTitle: "Mesto",
    searchPlaceholder: "Pretraga mesta",
    addButton: "Dodaj mesto",
    editTitle: "Izmena mesta",
    addTitle: "Dodavanje mesta",
    confirmClose: "Da li želite da napustite izmenu mesta?",
    confirmDelete: "Da li ste sigurni da želite da obrišete mesto?",
    rowsName: "mesta",
    addSuccessMessage: "Uspešno ste dodali novo mesto",
    addErrorMessage: "Greška prilikom dodavanja novog mesta",
    editSuccessMessage: "Uspešno ste izmenili mesto",
    editErrorMessage: "Greška prilikom izmene mesta",
    deleteSuccessMessage: "Uspešno ste obrisali mesto",
    deleteErrorMessage: "Greška prilikom uklanjanja mesta",
  },
  accommodationType: {
    pageTitle: "Tip objekta",
    searchPlaceholder: "Pretraga tipa objekata",
    addButton: "Dodaj tip objekta",
    editTitle: "Izmena tipa objekta",
    addTitle: "Dodavanje tipa objekta",
    confirmClose: "Da li želite da napustite izmenu tipa objekta?",
    confirmDelete: "Da li ste sigurni da želite da obrišete tip objekta?",
    rowsName: "tipa objekta",
    addSuccessMessage: "Uspešno ste dodali novi tip objekta",
    addErrorMessage: "Greška prilikom dodavanja novog tipa objekta",
    editSuccessMessage: "Uspešno ste izmenili tip objekta",
    editErrorMessage: "Greška prilikom izmene tipa objekta",
    deleteSuccessMessage: "Uspešno ste obrisali tip objekta",
    deleteErrorMessage: "Greška prilikom uklanjanja tipa objekta",
  },
  accommodation: {
    pageTitle: "Objekat",
    searchPlaceholder: "Pretraga objekata",
    addButton: "Dodaj objekat",
    editTitle: "Izmena objekta",
    addTitle: "Dodavanje objekta",
    confirmClose: "Da li želite da napustite izmenu objekta?",
    confirmDelete: "Da li ste sigurni da želite da obrišete objekat?",
    rowsName: "objekata",
    addSuccessMessage: "Uspešno ste dodali novi objekat",
    addErrorMessage: "Greška prilikom dodavanja novog objekta",
    editSuccessMessage: "Uspešno ste izmenili objekat",
    editErrorMessage: "Greška prilikom izmene objekta",
    deleteSuccessMessage: "Uspešno ste obrisali objekat",
    deleteErrorMessage: "Greška prilikom uklanjanja objekta",
  },
  accommodationUnitType: {
    pageTitle: "Vrsta smeštajne jedinice",
    searchPlaceholder: "Pretraga vrsta smeštajnih jedinica",
    addButton: "Dodaj vrstu smeštajne jedinice",
    editTitle: "Izmena vrste smeštajne jedinice",
    addTitle: "Dodavanje vrste smeštajne jedinice",
    confirmClose: "Da li želite da napustite izmenu vrste smeštajne jedinice?",
    confirmDelete:
      "Da li ste sigurni da želite da obrišete vrstu smeštajne jedinice?",
    rowsName: "vrsta smeštajnih jedinica",
    addSuccessMessage: "Uspešno ste dodali novu vrstu smeštajne jedinice",
    addErrorMessage: "Greška prilikom dodavanja nove vrste smeštajne jedinice",
    editSuccessMessage: "Uspešno ste izmenili vrstu smeštajne jedinice",
    editErrorMessage: "Greška prilikom izmene vrste smeštajne jedinice",
    deleteSuccessMessage: "Uspešno ste obrisali vrstu smeštajne jedinice",
    deleteErrorMessage: "Greška prilikom uklanjanja vrste smeštajne jedinice",
  },
  accommodationUnitCharacteristics: {
    pageTitle: "Karakteristike smeštajne jedinice",
    searchPlaceholder: "Pretraga karakteristika",
    addButton: "Dodaj karakteristiku",
    editTitle: "Izmena karakteristike smeštajne jedinice",
    addTitle: "Dodavanje karakteristike smeštajne jedinice",
    confirmClose:
      "Da li želite da napustite izmenu karakteristike smeštajne jedinice?",
    confirmDelete:
      "Da li ste sigurni da želite da obrišete karakteristiku smeštajne jedinice?",
    rowsName: "karakteristika",
    addSuccessMessage:
      "Uspešno ste dodali novu karakteristiku smeštajne jedinice",
    addErrorMessage:
      "Greška prilikom dodavanja nove karakteristike smeštajne jedinice",
    editSuccessMessage:
      "Uspešno ste izmenili karakteristiku smeštajne jedinice",
    editErrorMessage:
      "Greška prilikom izmene karakteristike smeštajne jedinice",
    deleteSuccessMessage:
      "Uspešno ste obrisali karakteristiku smeštajne jedinice",
    deleteErrorMessage:
      "Greška prilikom uklanjanja karakteristike smeštajne jedinice",
  },
  services: {
    pageTitle: "Vrste usluga",
    searchPlaceholder: "Pretraga usluga",
    addButton: "Dodaj uslugu",
    editTitle: "Izmena usluge",
    addTitle: "Dodavanje usluge",
    confirmClose: "Da li želite da napustite izmenu usluge?",
    confirmDelete: "Da li ste sigurni da želite da obrišete uslugu?",
    rowsName: "usluga",
    addSuccessMessage: "Uspešno ste dodali novu uslugu",
    addErrorMessage: "Greška prilikom dodavanja nove usluge",
    editSuccessMessage: "Uspešno ste izmenili uslugu",
    editErrorMessage: "Greška prilikom izmene usluge",
    deleteSuccessMessage: "Uspešno ste obrisali uslugu",
    deleteErrorMessage: "Greška prilikom uklanjanja usluge",
  },
  transportationType: {
    pageTitle: "Vrsta prevoza",
    searchPlaceholder: "Pretraga vrste prevoza",
    addButton: "Dodaj vrstu prevoza",
    editTitle: "Izmena vrste prevoza",
    addTitle: "Dodavanje vrste prevoza",
    confirmClose: "Da li želite da napustite izmenu vrste prevoza?",
    confirmDelete: "Da li ste sigurni da želite da obrišete vrstu prevoza?",
    rowsName: "vrsta prevoza",
    addSuccessMessage: "Uspešno ste dodali novu vrstu prevoza",
    addErrorMessage: "Greška prilikom dodavanja nove vrste prevoza",
    editSuccessMessage: "Uspešno ste izmenili vrstu prevoza",
    editErrorMessage: "Greška prilikom izmene vrste prevoza",
    deleteSuccessMessage: "Uspešno ste obrisali vrstu prevoza",
    deleteErrorMessage: "Greška prilikom uklanjanja vrste prevoza",
  },
  offerType: {
    pageTitle: "Tip ponude",
    searchPlaceholder: "Pretraga tipa ponuda",
    addButton: "Dodaj tip ponude",
    editTitle: "Izmena tipa ponude",
    addTitle: "Dodavanje tipa ponude",
    confirmClose: "Da li želite da napustite izmenu tipa ponude?",
    confirmDelete: "Da li ste sigurni da želite da obrišete tip ponude?",
    rowsName: "tipa ponuda",
    addSuccessMessage: "Uspešno ste dodali novi tip ponude",
    addErrorMessage: "Greška prilikom dodavanja novog tipa ponude",
    editSuccessMessage: "Uspešno ste izmenili tip ponude",
    editErrorMessage: "Greška prilikom izmene tipa ponude",
    deleteSuccessMessage: "Uspešno ste obrisali tip ponude",
    deleteErrorMessage: "Greška prilikom uklanjanja tipa ponude",
  },
  agency: {
    rowsName: "agencija",
    addButton: "Dodaj agenciju",
    confirmClose: "Da li ste sigurni da želite da odustanete?",
    addSuccessMessage: "Uspešno ste dodali novu agenciju",
    addErrorMessage: "Greška prilikom dodavanja nove agencije",
    editSuccessMessage: "Uspešno ste izmenili agenciju",
    editErrorMessage: "Greška prilikom izmene agencije",
    toggleSuccessMessage: "Uspešno ste izmenili status agencije",
    toggleErrorMessage: "Greška prilikom izmene statusa agencije",
    confirmAgencyStatus: "Da li ste sigurni da želite da {{status}} agenciju?",
    editLogoSuccessMessage: "Uspešno ste izmenili logo agencije",
    editSignatureSuccessMessage: "Uspešno ste izmenili potpis ovlašćenog lica",
    editLogoErrorMessage: "Greška prilikom izmene logoa agencije",
    editSignatureErrorMessage: "Greška prilikom izmene potpisa ovlašćenog lica",
    deleteLogoSuccessMessage: "Uspešno ste obrisali logo agencije",
    deleteSignatureSuccessMessage:
      "Uspešno ste obrisali potpis ovlašćenog lica",
    deleteLogoErrorMessage: "Greška prilikom brisanja logoa agencije",
    deleteSignatureErrorMessage:
      "Greška prilikom brisanja potpisa ovlašćenog lica",
    deactivateAgency: "deaktivirate",
    activateAgency: "aktivirate",
    logoDescriptionText: "Odaberite ili prevucite logo agencije",
    signatureDescriptionText: "Odaberite ili prevucite potpis ovlašćenog lica",
    section: {
      basicInfo: "Osnovne informacije",
      agencyInfo: "Informacije o agenciji",
      licenceInfo: "Informacije o licenci za organizatore",
      systemLicenceInfo: "Informacije o sistemskoj licenci",
      additionalInfo: "Ostale informacije",
    },
  },
  partners: {
    pageTitle: "Partneri",
    rowsName: "partnera",
    addPartnerFromSystem:
      "Da li ste sigurni da želite da dodate partnera iz sistema?",
    dropdownPartnersList: "Dodaj partnera iz sistema",
    addButton: "Dodaj partnera - van sistema",
    confirmCancel: "Da li ste sigurni da želite da odustanete?",
    addSuccessMessage: "Uspešno ste dodali novog partnera",
    addErrorMessage: "Greška prilikom dodavanja novog partnera",
    editSuccessMessage: "Uspešno ste izmenili partnera",
    editErrorMessage: "Greška prilikom izmene partnera",
    deleteSuccessMessage: "Uspešno ste obrisali partnera",
    deleteErrorMessage: "Greška prilikom uklanjanja partnera",
    confirmDeletePartner: "Da li ste sigurni da želite da obrišete partnera?",
    addTitle: "Dodaj novog partnera",
    editTitle: "Izmeni partnera",
    sections: {
      basicInfo: "Osnovni podaci o partneru",
    },
  },
  users: {
    rowsName: "korisnika",
    addButton: "Dodajte korisnika",
    addTitle: "Dodavanje korisnika",
    editTitle: "Izmena korisnika",
    addSuccessMessage: "Uspešno ste dodali novog korisnika",
    addErrorMessage: "Greška prilikom dodavanja novog korisnika",
    editSuccessMessage: "Uspešno ste izmenili korisnika",
    editErrorMessage: "Greška prilikom izmene korisnika",
    deleteSuccessMessage: "Uspešno ste obrisali korisnika",
    deleteErrorMessage: "Greška prilikom uklanjanja korisnika",
    toggleSuccessMessage: "Uspešno ste promenili status korisnika",
    toggleErrorMessage: "Greška prilikom promene statusa korisnika",
    passwordChangeSuccessMessage: "Uspešno ste izmenili lozinku korisnika",
    passwordChangeErrorMessage: "Greška prilikom promene lozinke korisnika",
    confirmUserStatus: "Da li ste sigurni da želite da {{status}} korisnika?",
    deactivateUser: "deaktivirate",
    activateUser: "aktivirate",
    confirmDeleteUser: "Da li ste sigurni da želite da obrišete korisnika?",
    cancelConfirm: "Da li ste sigurni da želite da odustanete?",
    confirmDeleteImage: "Da li ste sigurni da želite da obrišete sliku?",
    section: {
      basicInfo: "Osnovni podaci o korisniku",
      personalInfo: "Lične informacije",
      agencyInfo: "Informacije o agenciji",
      profilePictureInfo: "Slika korisnika aplikacije",
    },
  },
  licence: {
    rowsName: "licenci",
    addButton: "Dodaj licencu",
    addSuccessMessage: "Uspešno ste dodali novu licencu",
    addErrorMessage: "Greška prilikom dodavanja nove licence",
    editSuccessMessage: "Uspešno ste izmenili licencu",
    editErrorMessage: "Greška prilikom izmene licence",
    addTitle: "Dodajte licencu",
    editTitle: "Izmena podataka o licenci",
    cancelConfirm: "Da li ste sigurni da želite da odustanete?",
  },
  client: {
    pageTitle: "Klijenti",
    addButton: "Dodaj klijenta",
    editTitle: "Izmena klijenta",
    addTitle: "Dodavanje klijenta",
    confirmClose: "Da li želite da napustite izmenu klijenta?",
    confirmDelete: "Da li ste sigurni da želite da obrišete klijenta?",
    rowsName: "klijenta",
    addSuccessMessage: "Uspešno ste dodali novog klijenta",
    addErrorMessage: "Greška prilikom dodavanja novog klijenta",
    editSuccessMessage: "Uspešno ste izmenili klijenta",
    editErrorMessage: "Greška prilikom izmene klijenta",
    deleteSuccessMessage: "Uspešno ste obrisali klijenta",
    deleteErrorMessage: "Greška prilikom uklanjanja klijenta",
    passportImageDescriptionText: "Odaberite ili prevucite sliku pasoša",
    addPassportImageSuccessMessage: "Uspešno ste dodali sliku pasoša",
    addPassportImageErrorMessage: "Greška prilikom dodavanja slike pasoša",
    deletePassportImageSuccessMessage: "Uspešno ste obrisali sliku pasoša",
    deletePassportImageErrorMessage: "Greška prilikom brisanja slike pasoša",
    sections: {
      basicInfo: "Osnovni podaci o klijentu",
      passportInfo: "Podaci o pasošu",
    },
  },
  internalSale: {
    pageTitle: "Interna prodaja - Ponude svih partnera",
    rowsName: "ponuda",
    reserveConfirmation: "Da li ste sigurni da želite da rezervišete ponudu?",
    preview: "Pregled ponude",
  },
  payment: {
    addButton: "Dodaj priznanicu",
    receiptsTitle: "Priznanice",
    receiptNumber: "Priznanica broj: ",
    addSuccessMessage: "Uspešno ste dodali priznanicu",
    editSuccessMessage: "Uspešno ste dodali priznanicu",
    deleteSuccessMessage: "Uspešno ste obrisali priznanicu",
    deleteErrorMessage: "Greška prilikom uklanjanja priznanice",
    confirmClose: "Da li želite da napustite izmenu priznanice?",
    confirmDelete: "Da li ste sigurni da želite da obrišete priznanicu?",
    rowsName: "priznanicu",
  },
  offers: {
    pageTitle: "Ponude",
    addButton: "Dodaj ponudu",
    editTitle: "Izmena ponude",
    addTitle: "Dodavanje ponude",
    confirmClose: "Da li želite da napustite izmenu ponude?",
    confirmOfferStatus: "Da li ste sigurni da želite da {{status}} ponudu?",
    confirmDelete: "Da li ste sigurni da želite da obrišete ponudu?",
    rowsName: "ponuda",
    addSuccessMessage: "Uspešno ste dodali novu ponudu",
    addErrorMessage: "Greška prilikom dodavanja nove ponude",
    editSuccessMessage: "Uspešno ste izmenili ponudu",
    editErrorMessage: "Greška prilikom izmene ponude",
    deleteSuccessMessage: "Uspešno ste obrisali ponudu",
    deleteErrorMessage: "Greška prilikom uklanjanja ponude",
    toggleStatusOfferSuccessMessage: "Uspešno ste promenili status ponude",
    toggleStatusOfferErrorMessage: "Greška prilikom promene statusa ponude",
    activateOffer: "aktivirate",
    deactivateOffer: "deaktivirate",
  },
  statement: {
    pageTitle: "Izvodi",
    addButton: "Dodaj izvod",
    editTitle: "Izmena izvoda",
    addTitle: "Dodaj izvod",
    confirmDelete: "Da li ste sigurni da želite da obrišete izvod?",
    rowsName: "izvoda",
    addSuccessMessage: "Uspešno ste dodali novi izvod",
    addErrorMessage: "Greška prilikom dodavanja novog izvoda",
    editSuccessMessage: "Uspešno ste izmenili izvod",
    editErrorMessage: "Greška prilikom izmene izvoda",
    deleteSuccessMessage: "Uspešno ste obrisali izvod",
    deleteErrorMessage: "Greška prilikom uklanjanja izvoda",
    addNewItem: "Dodaj novu stavku",
    sections: {
      basicInfo: "Osnovne informacije o izvodu",
      statementItemsInfo: "Stavke izvoda",
    }
  },
  exchangeRate: {
    title: "Dodaj srednji kurs NBS",
    editTitle: "Izmena srednjeg kursa NBS",
    addSuccessMessage: "Uspešno ste dodali srednji kurs NBS",
    editSuccessMessage: "Uspešno ste izmenili srednji kurs NBS",
    addButton: "Dodaj srednji kurs NBS",
  },
  accommodationUnit: {
    pageTitle: "Definisanje objekta - {{name}}",
    addButton: "Dodaj novu smeštajnu jedinicu",
    rowsName: "smeštajnih jedinica",
    editTitle: "Izmena smeštajne jedinice - objekat {{name}}",
    addTitle: "Dodavanje smeštajne jedinice - objekat {{name}}",
    confirmClose: "Da li želite da napustite izmenu smeštajne jedinice?",
    confirmDelete:
      "Da li ste sigurni da želite da obrišete smeštajnu jedinicu?",
    addSuccessMessage: "Uspešno ste dodali novu smeštajnu jedinicu",
    addErrorMessage: "Greška prilikom dodavanja nove smeštajne jedinice",
    editSuccessMessage: "Uspešno ste izmenili smeštajnu jedinicu",
    editErrorMessage: "Greška prilikom izmene smeštajne jedinice",
    deleteSuccessMessage: "Uspešno ste obrisali smeštajnu jedinicu",
    deleteErrorMessage: "Greška prilikom uklanjanja smeštajne jedinice",
  },
  admin: {
    addAgencyTitle: "Dodavanje agencije",
    tabs: {
      agency: "Agencija",
      users: "Korisnici",
      licences: "Licence",
    },
  },
  settings: {
    tabs: {
      agencyInfo: "Osnovni podaci o agenciji",
      users: "Korisnici",
      licences: "Licence",
      print: "Podešavanja štampe",
    },
    printTab: {
      info: "Opcije za štampu dokumenta",
    },
  },
  profile: {
    tabs: {
      profileBasicInfo: "Osnovne informacije",
      changePassword: "Izmena lozinke",
    },
    changePasswordSuccessMessage: "Uspešno ste izmenili lozinku",
    profilePicture: "Profilna slika",
    deleteProfileImageSuccessMessage: "Uspešno ste obrisali profilnu sliku",
    deleteProfileImageErrorMessage: "Greška prilikom brisanja profilne slike",
  },
  tableCols: {
    user: {
      nameLabel: "Ime",
      surnameLabel: "Prezime",
      roleLabel: "Rola",
      emailLabel: "Email",
      phoneNumberLabel: "Broj telefona",
      agencyNameLabel: "Poslovnica",
      statusLabel: "Status",
      lastChangesLabel: "Poslednje izmene",
      userStatusActive: "Aktivan",
      userStatusInactive: "Neaktivan",
      tooltip: {
        change: "Izmeni",
        resetPassword: "Resetuj lozinku",
        activateUser: "Aktiviraj korisnika",
        blockUser: "Deaktiviraj korisnika",
        deleteUser: "Obriši korisnika",
      },
    },
    agency: {
      ordinalNumberLabel: "Redni broj",
      agencyNameLabel: "Naziv agencije",
      agencyTypeLabel: "Tip agencije",
      addressLabel: "Adresa",
      cityLabel: "Mesto",
      countryLabel: "Država",
      emailLabel: "Email",
      systemLicenceNumberLabel: "Broj sistemske licence",
      expirationDateLabel: "Datum isteka licence",
      lastChangesLabel: "Poslednje izmene",
      agencyStatusActive: "Aktivna",
      agencyStatusInactive: "Neaktivna",
      tooltip: {
        details: "Detalji",
        activate: "Aktiviraj",
        deactivate: "Deaktiviraj",
      },
    },
    partner: {
      ordinalNumberLabel: "Redni broj",
      partnerNameLabel: "Naziv partnera",
      partnerTypeLabel: "Tip partnera",
      addressLabel: "Adresa",
      cityLabel: "Mesto",
      countryLabel: "Država",
      pibLabel: "PIB",
      legalIdentificationNumberLabel: "Matični broj",
      emailLabel: "Email",
      lastChangesLabel: "Poslednje izmene",
      tooltip: {
        details: "Detalji",
        activate: "Aktiviraj",
        deactivate: "Deaktiviraj",
      },
    },
    offer: {
      offerStatusActive: "Aktivna",
      offerStatusInactive: "Neaktivna",
      tooltip: {
        activateOffer: "Aktiviraj ponudu",
        deactivateOffer: "Deaktiviraj ponudu",
      },
    },
  },
  password: {
    weak: "weak",
    average: "average",
    good: "good",
    strong: "strong",
  },
  forgotPassword: {
    title: "Forgot Password",
    label: "Send email",
    emailRequired: "An email is required.",
    emailFormat: "Invalid email address format.",
    forgotPassword: {
      title: "Forgot Password",
      subtitle:
        "Please answer the security question to gain access to your account:",
      label: "Reset Password",
    },
  },
  notFound: {
    text: "We're sorry but we couldn't find the page you were looking for.",
    goBack: "Go back to homepage",
  },
  errorPage: {
    text: "We're sorry, an internal server error came up. Please be patient or try again later.",
    goBack: "Go back to homepage",
    logout: "Logout",
  },
  apiErrors: {
    ClientIpAddressIsNullOrEmpty: "Client Ip address is null or empty",
    UsernameDoesNotExist: "Username does not exist",
    WrongCredentials: "Wrong credentials",
    SomethingWentWrong: "Something went wrong",
    WrongPasswordAccountIsLocked: "Wrong credentials, account is locked",
    AccountIsLocked: "Account is locked",
  },
  date: {
    timespan: {
      yearsAgo: "Pre {{years}} godine",
      monthsAgo: "Pre {{months}} meseca",
      daysAgo: "Pre {{days}} dana",
      hoursAgo: "Pre {{hours}} sata",
      minutesAgo: "Pre {{minutes}} minuta",
      secondsAgo: "Pre {{seconds}} sekunde",
      now: "Upravo sada",
    },
  },
  dashboard: {
    charts: {
      lineChart: "Linijski grafikon",
      pieChart: "Kružni grafikon",
      barChart: "Dijagram",
      radarChart: "Radarski grafikon",
    },
  },
};
