export default {
  number: "",
  date: null,
  accountNumber: "",
  items: [
    {
      clientPartner: "",
      creditDebit: "",
      amount: "",
      foreignAmount: "",
      totalAmount: "",
      description: "",
      exchangeRate: "",
      clientId: "",
      invoiceNumber: "",
      reservationNumber: "",
    },
  ],
};
