import { Box, IconButton, InputAdornment } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FormContainer, InformationContainer, InformationMainText, RowContainer } from "styles/SharedStyle.styled";
import FormActions from "../FormActions/FormActions";
import useAuth from "hooks/useAuth";
import { useUserRolesQuery } from "features/user/userApiSlice";
import { phoneNumberChars } from "util/helpers/preventNonNumericChars";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const UserForm = ({ formik, isAdd }) => {
  const { isSuperAdmin } = useAuth();
  const { data: roles } = useUserRolesQuery();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleChange = (e) => {
    e.preventDefault();
  };
  return (
    <Box component="form">
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("users.section.basicInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer>
          <TextInput
            fullWidth
            name="surname"
            label={t("field.surnamePlaceholder")}
            value={formik.values.surname}
            onChange={formik.handleChange}
            error={formik.touched.surname && Boolean(formik.errors.surname)}
            helperText={formik.touched.surname && formik.errors.surname}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="name"
            label={t("field.namePlaceholder")}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </RowContainer>

        <RowContainer>
          <SelectInput
            fullWidth
            name="roleId"
            label={t("field.roleIdRequiredPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values.roleId}
            error={formik.touched.roleId && Boolean(formik.errors.roleId)}
            helperText={formik.touched.roleId && formik.errors.roleId}
            items={roles}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="office"
            label={t("field.officePlaceholder")}
            value={formik.values.office}
            onChange={formik.handleChange}
          />
        </RowContainer>

        <RowContainer>
          <TextInput
            name="phoneNumber"
            fullWidth
            label={t("field.phoneNumberPlaceholder")}
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            onKeyDown={phoneNumberChars}
            style={{ marginRight: isAdd && "24px" }}
          />
          <TextInput
            fullWidth
            name="email"
            disabled={!isSuperAdmin && !isAdd}
            label={t("field.emailPlaceholder")}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </RowContainer>

        <RowContainer>
          {isAdd && (
            <TextInput
              name="password"
              label={t("field.passwordPlaceholder")}
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              fullWidth
              onCut={handleChange}
              onCopy={handleChange}
              onPaste={handleChange}
              style={{ marginRight: isAdd && "24px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          {isAdd && (
            <TextInput
              name="confirmPassword"
              label={t("field.confirmPasswordPlaceholder")}
              type={showConfirmPassword ? "text" : "password"}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              fullWidth
              onCut={handleChange}
              onCopy={handleChange}
              onPaste={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </RowContainer>
      </FormContainer>

      <FormActions
        disabled={!formik.dirty}
        handleCancel={formik.resetForm}
        handleConfirm={formik.handleSubmit}
      />
    </Box>
  );
};
UserForm.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    touched: PropTypes.object,
    errors: PropTypes.object,
    resetForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
  }).isRequired,
  isAdd: PropTypes.bool.isRequired,
};
export default UserForm;
