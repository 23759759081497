/* eslint-disable no-unused-vars */
import { Button } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import StatementFilter from "components/Filter/StatementFilter/StatementFilter";
import Filter from "components/Filter/Filter";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import Title from "components/Title/Title";
import modalConstants from "constants/modalConstants";
import { PAGES } from "constants/pages";
import { closeModal } from "features/modal/modalSlice";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { getNonEmptyProps } from "util/helpers/objectHelpers";
import useStatementTable from "hooks/tables/useStatementTable";
//import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";

const StatementsPage = () => {
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modal.modals);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState(null);
  const { columns } = useStatementTable({
    page,
    rowsPerPage,
  });
  const deleteModal = modals?.[modalConstants.DELETE_STATEMENT_MODAL];
  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
  };
  const filterForm = useFormik({
    initialValues: { Date: null, AccountId: "", StatementNumber: "" },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  const handleClose = () => {
    dispatch(closeModal({ id: modalConstants.DELETE_STATEMENT_MODAL }));
  };
  const handleConfirmDelete = () => {
    console.log("delete");
  };
  return (
    <PageContainer>
      <ConfirmModal
        open={deleteModal?.open}
        handleConfirm={handleConfirmDelete}
        handleCancel={handleClose}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={t("statement.confirmDelete")}
      />
      <Title title={t("statement.pageTitle")} />
      <PageHeader>
        <Filter
          filterForm={filterForm}
          handleReset={() => {
            setFilter(null);
            filterForm.resetForm();
          }}
        >
          <StatementFilter filterForm={filterForm} />
        </Filter>
        <Button variant="contained" onClick={() => navigate(PAGES.ADDSTATEMENT.route)}>
          {t("statement.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={[]}
        isLoading={false}
        //count={clientList?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("statement.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default StatementsPage;
