import { Typography } from "@mui/material";
import ReceiptForm from "components/Forms/ReceiptForm/ReceiptForm";
import { PAGES } from "constants/pages";
import {
  useAddReceiptMutation,
  useEditReceiptMutation,
  useReceiptDetailsQuery,
} from "features/receipts/receiptsSlice";
import { useFormik } from "formik";
import receiptsInitialValues from "initialValues/receiptsInitialValues";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "styles/SharedStyle.styled";
import { convertDateToISO } from "util/dateHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import receiptsValidation from "validations/receiptsValidation";

const AddEditReceiptPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const path = useLocation().pathname;
  const isAdd = path === PAGES.ADDRECEIPT.route;
  const navigate = useNavigate();
  const [addReceipt, { isLoading: isLoadingAddReceipt }] =
    useAddReceiptMutation();
  const [editReceipt, { isLoading: isLoadingEditReceipt }] =
    useEditReceiptMutation();
  const { data: receiptDetails, isLoading: isLoadingReceiptDetails } =
    useReceiptDetailsQuery({ id }, { skip: isAdd });

  const handleSubmit = async (values) => {
    if (isAdd) {
      try {
        await addReceipt({
          ...values,
          date: convertDateToISO(values.date),
        })
          .unwrap()
          .then(() => {
            if (!isLoadingAddReceipt) {
              navigate(PAGES.RECEIPTS.route);
              makeToastMessage(t("payment.addSuccessMessage"));
            }
          });
      } catch (e) {
        makeErrorToastMessage(e.data.message);
      }
    }

    if (id) {
      try {
        await editReceipt({
          ...values,
          dateOfBirth: convertDateToISO(values.dateOfBirth),
          passportExpirationDate: values.passportExpirationDate
            ? convertDateToISO(values.passportExpirationDate)
            : null,
        })
          .unwrap()
          .then(() => {
            if (!isLoadingEditReceipt) {
              navigate(PAGES.RECEIPTS.route);
              makeToastMessage(t("payment.editSuccessMessage"));
            }
          });
      } catch (e) {
        makeErrorToastMessage(e.data.message);
      }
    }
  };
  const formik = useFormik({
    initialValues:
      id && !isLoadingReceiptDetails
        ? {
            ...receiptDetails,
            date: new Date(receiptDetails?.date),
          }
        : receiptsInitialValues,
    validationSchema: receiptsValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  return (
    <PageContainer>
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginBottom: "24px" }}
      >
        {t("payment.receiptNumber")} 233/2024
      </Typography>
      <ReceiptForm formik={formik} />
    </PageContainer>
  );
};

export default AddEditReceiptPage;
